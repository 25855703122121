//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { STable, XCard } from '@/components'
import { flowableShortcutPage, flowableShortcutDelete } from '@/api/modular/flowable/shortcutManage'
import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import editForm from './editForm'
export default {
  components: {
    XCard,
    STable,
    editForm
  },
  data () {
    return {
      // 高級搜索 展開/關閉
      advanced: false,
      // 查詢參數
      queryParam: {},
      // 表頭
      columns: [
        {
          title: '入口名稱',
          dataIndex: 'name'
        },
        {
          title: '圖標',
          dataIndex: 'icon',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '分類名稱',
          dataIndex: 'categoryName'
        },
        {
          title: '排序',
          dataIndex: 'sort'
        },
        {
          title: '狀態',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' }
        }
      ],
      // 加載數據方法 必須為 Promise 對象
      loadData: parameter => {
        return flowableShortcutPage(Object.assign(parameter, this.queryParam)).then((res) => {
          return res.data
        })
      },
      selectedRowKeys: [],
      selectedRows: [],
      flowableCategoryListData: [],
      statusDictTypeDropDown: []
  }
  },

  created () {
    this.flowableCategoryList()
    this.sysDictTypeDropDown()
    if (this.hasPerm('flowableShortcut:edit') || this.hasPerm('flowableShortcut:delete')) {
      this.columns.push({
        title: '操作',
        width: '150px',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' }
      })
    }
  },

  methods: {

    statusFilter (status) {
      // eslint-disable-next-line eqeqeq
      const values = this.statusDictTypeDropDown.filter(item => item.code == status)
      if (values.length > 0) {
        return values[0].value
      }
    },

    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      sysDictTypeDropDown({ code: 'common_status' }).then((res) => {
        this.statusDictTypeDropDown = res.data
      })
    },

    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },

    flowableShortcutDelete (record) {
      flowableShortcutDelete(record).then((res) => {
        if (res.success) {
          this.$message.success('刪除成功')
          this.$refs.table.refresh()
        } else {
          this.$message.error('刪除失敗：' + res.message)
        }
      }).catch((err) => {
        this.$message.error('刪除錯誤：' + err.message)
      })
    },

    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    handleOk () {
      this.$refs.table.refresh()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }

}
