//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { flowableCategoryList } from '@/api/modular/flowable/categoryManage'
import { flowableShortcutEdit } from '@/api/modular/flowable/shortcutManage'
import IconSelector from '@/components/IconSelector'

export default {
  components: { IconSelector },
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      flowableCategoryListData: [],
      visibleIcon: false,
      // 圖標組件
      currentSelectedIcon: ''
    }
  },
  created () {
    this.flowableCategoryList()
  },
  methods: {
    // 初始化方法
    edit (record) {
      this.visible = true
      this.currentSelectedIcon = record.icon
      setTimeout(() => {
        this.form.setFieldsValue(
          {
            id: record.id,
            key: record.key,
            name: record.name,
            category: record.category,
            categoryName: record.categoryName,
            version: record.version,
            sort: record.sort,
            description: record.description,
            processDefinitionId: record.processDefinitionId
          }
        )
      }, 100)
      this.form.getFieldDecorator('icon', { initialValue: record.icon })
    },

    /**
     * 獲取流程類型
     */
    flowableCategoryList () {
      flowableCategoryList().then((res) => {
        this.flowableCategoryListData = res.data
      })
    },

    /**
     * 分類下拉事件，初始化分類名稱
     */
    categorySelect (value) {
      this.form.getFieldDecorator('categoryName', { initialValue: this.flowableCategoryListData.find(item => value === item.code).name })
    },

    /**
     * 選擇圖標
     */
    openIconSele () {
      this.visibleIcon = true
    },
    handleIconChange (icon) {
      this.currentSelectedIcon = icon
      this.form.getFieldDecorator('icon', { initialValue: icon })
      this.visibleIcon = false
    },
    handleCancelIcon () {
      this.visibleIcon = false
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          flowableShortcutEdit(values).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('編輯成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('編輯失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.currentSelectedIcon = ''
      this.form.getFieldDecorator('icon', { initialValue: '' })
      this.form.resetFields()
      this.visible = false
    }
  }
}
